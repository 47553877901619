import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const JobDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const job = location.state?.job;

  if (!job) {
    return <Typography>No job details available.</Typography>;
  }

  return (
    <Box className="jobDetailContainer" sx={{ padding: {
      xs: '20px 20px', 
      sm: '20px 50px', 
      md: '20px 100px', 
      lg: '20px 200px',
      xl: '20px 400px', 
    },margin:'0 auto' }}>
      <Button onClick={() => navigate("/careers")} variant="contained">Back</Button>

      <Typography variant="h4" sx={{ marginTop: '20px' }}>
        {job.title}
      </Typography>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        Job Description
      </Typography>
      <Typography>{job.jd}</Typography>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        Responsibilities
      </Typography>
      <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
        {job.responsibilities.split('\n').map((item, index) => (
          <li key={index}>
            <Typography>{item.trim()}</Typography>
          </li>
        ))}
      </ul>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        Requirements
      </Typography>
      <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
        {job.requirements.split('\n').map((item, index) => (
          <li key={index}>
            <Typography>{item.trim()}</Typography>
          </li>
        ))}
      </ul>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        Preferred Qualifications
      </Typography>
      <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
        {job.preferred_qualifications.split('\n').map((item, index) => (
          <li key={index}>
            <Typography>{item.trim()}</Typography>
          </li>
        ))}
      </ul>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        Key Competencies
      </Typography>
      <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
        {job.key_competencies.split('\n').map((item, index) => (
          <li key={index}>
            <Typography>{item.trim()}</Typography>
          </li>
        ))}
      </ul>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        About CoGuide
      </Typography>
      <Typography>{job.about_coguide}</Typography>

      <Typography variant="h6" sx={{ marginTop: '20px' }}>
        How to Apply
      </Typography>
      <Typography>{job.how_to_apply}</Typography>
    </Box>
  );
};

export default JobDetail;