import Box from '@mui/material/Box';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Careers.css';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

import { EffectFade, Navigation, Pagination, Mousewheel } from 'swiper/modules';
import { Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import JobCard from "./JobCard";
import JobDetail from "./JobDetail";
import './Careers.css'
import { useNavigate } from 'react-router';
import { getJobDescriptions } from '../../api/evidence';

const Careers = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate()
  
  useEffect(() => {
    const fetchJobs = async () => {
      let response = await getJobDescriptions();
      console.log(response)
      setJobs(response.data);
      setFilteredJobs(response.data);
    };
    if(jobs.length === 0){
      fetchJobs();
    }
  }, []);

  useEffect(() => {
    const filtered = jobs && jobs.filter(job => 
      job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredJobs(filtered);
  }, [searchTerm, jobs]);

  const handleCardClick = (job) => {
    navigate(`/job/${job.id}`,{state:{job}})
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Stack className="careers-page">
      <div className='careers-header-container'>
      <Box
            sx={{
              padding: '20px 30px',
              fontSize: { xs: '2rem', md: '3rem' },
              height: '100%',
            }}
          >
            Become Part Of Our Team
      </Box>
      <div className="roundIcon">
            {Array(4)
              .fill(true)
              .map((item, index) => (
                <FiberManualRecordRoundedIcon
                  key={index}
                  sx={{
                    color: '#005AD2',
                    fontSize: { xs: 15, md: 20 },
                  }}
                />
              ))}
          </div>
      </div>
      <Box sx={{
        margin:'0 auto'
      }}>
        <TextField
        label="Search jobs"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ margin: '20px', width: '300px'}}
        size="small"
      />
      </Box>
      <div className="jobs-list">
        {filteredJobs && filteredJobs.map((job) => (
          <JobCard key={job.id} job={job} onClick={handleCardClick} />
        ))}
      </div>
      <Box
            sx={{
              padding: '20px 30px',
              fontSize: { xs: '1.5rem', md: '2rem' },
              height: '100%',
              textAlign:'center'
            }}
          >
            Send Your Resume To HR 
            <span className='hr-email'> career@coguide.in</span>
      </Box>
    </Stack>
  );
};

export default Careers;
