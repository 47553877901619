import "./ReportingTools.css";

const ReportingTools = () => {
  return (
    <div className="reporting-tools-container">
      <div className="reporting-tools">
        <h1>Reporting Tools</h1>
      </div>
      <div className="reporting-tools forms-container">
        <h2>Forms</h2>
        <hr />
        <a
          href="https://reapv2.coguide.in/?topicId=C9488&formId=65c79744c95133372ee24039"
          target="_blank"
        >
          Incident Reporting Form
        </a>
        <a
          href="https://reapv2.coguide.in/?topicId=C9488&formId=660b9b52b884a7fd54867f9f"
          target="_blank"
        >
          Investigation Documentation Form
        </a>
        <a
          href="https://reapv2.coguide.in/?topicId=C9488&formId=660b9de8b884a7fd54867fb0"
          target="_blank"
        >
          Actions Documentation Form
        </a>
      </div>
      <div className="reporting-tools">
        <h2>Data Protection Officer</h2>
        <hr />
        <p>Phanichaitanya C</p>
        <p>Phone: 7093445505</p>
        <p>
          Email: <a href="mailto:phani@coguide.in">phani@coguide.in</a>
        </p>
      </div>
    </div>
  );
};

export default ReportingTools;
