import { Stack } from "@mui/material";
import React from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const PharmaIndustry = () => {
  return (
    <Stack>
      <VideoPlayer src="/videos/coguideexplainer_2.mp4" />
    </Stack>
  );
};

export default PharmaIndustry;
